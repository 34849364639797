import HomeIcon from '../../icons/HOME.png'
import './HomeSection.css'
import Mountain from '../../icons/mountain.png'
import { BackgroundTitle } from '../BackgroundTitle/BackgroundTitle'

const HomeSection = () => {
    return (
        <section id="home-section" className="text-center">
            <div className="home-content">
                <div className='home-bg'>
                    <BackgroundTitle icon={HomeIcon} paddingTop="15rem" />
                    <div className="mountain-art-container">
                        <img src={Mountain} alt='art' className='mountain-art' />
                    </div>
                </div>
                <div className="home-subcontent">
                    <h1 className='title-text' >
                        Your Brand Our Passion Welcome to
                    </h1>
                    <h1 className='title-text-c'>
                        COVELPER
                    </h1>
                    <p className='description-text'>
                        Transforming brands with creativity and strategy. Experience the power of innovation at its finest.
                    </p>
                    <div>
                        {/* <div className='flex justify-center'>
            <Image src={meetingImage} width={500} height={200} alt="" />
            </div> */}
                    </div>
                </div>
            </div>
        </section>
    )
}

export default HomeSection
