import React, { useState } from 'react'
import './Card.css'

interface ICardProps {
    image: any;
    title: string;
    description: string;
    onClick: () => void;
}

const Card: React.FC<ICardProps> = ({ image, title, description, onClick }) => {
    const [isPressed, setIsPressed] = useState(false);

    const handleMouseDown = () => {
        setIsPressed(true);
    };

    const handleMouseUp = () => {
        setIsPressed(false);
    };

    const handleMouseLeave = () => {
        setIsPressed(false);
    };
    // <div onClick={onClick} className='card-container'>
    return (
        <div
            onClick={onClick}
            onMouseDown={handleMouseDown}
            onMouseUp={handleMouseUp}
            onMouseLeave={handleMouseLeave}
            className={`card-container ${isPressed ? 'pressed' : ''}`}
        >
            <div className='card-content' >
                <div className='card-icon-container'>
                    <img src={image} alt='image' className='card-icon' />
                </div>
                <div className='title-container'>
                    <h1 className='title-text'>{title}</h1>
                </div>
                <div className='description-container'>
                    <p className='description-text'>{description}</p>
                </div>
            </div>
        </div>
    );
}

export default Card;
