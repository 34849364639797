import { CSSProperties, useState } from 'react';
import './Contact.css'
import C from '../../icons/C.png'
import axios from 'axios';
import ClipLoader from "react-spinners/ClipLoader";
import toast, { Toaster } from 'react-hot-toast';
import { ENDPOINTS } from '../../constants/api';
import APP_LOGO from '../../icons/web_logo.png'
import INSTAICON from '../../icons/INSTAGRAM.png'
import LINKEDINICON from '../../icons/LINKEDIN.png'

interface IFormProps {
  fullName: string,
  email: string,
  details: string,
  checklist: {
    WEB_DEV?: boolean,
    MOBILE_DEV?: boolean,
    SOFTWARE_MAINTAINER?: boolean,
    OTHER?: boolean,
  }
}
interface IErrorProps {
  fullName?: string;
  email?: string;
  details?: string;
}

const override: CSSProperties = {
  display: "block",
  margin: "0 auto",
  borderColor: "red",
};



const Contact = () => {
  const [loading, setLoading] = useState(false);
  const [color, setColor] = useState("#ffffff");
  const [formData, setFormData] = useState<IFormProps>({
    fullName: '',
    email: '',
    details: '',
    checklist: {
      WEB_DEV: true,
      MOBILE_DEV: false,
      SOFTWARE_MAINTAINER: false,
      OTHER: false,
    }
  });

  const [errors, setErrors] = useState<IErrorProps>({
    fullName: '',
    email: '',
    details: '',
  });


  const handleChange = (e: any) => {
    const { name, value, type, checked } = e.target;
    if (type === 'checkbox') {
      if (checked) {
        setFormData({
          ...formData,
          checklist: {
            WEB_DEV: false,
            MOBILE_DEV: false,
            SOFTWARE_MAINTAINER: false,
            OTHER: false,
            [name]: checked
          }
        });
      }
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };


  const validate = () => {
    let formErrors: IErrorProps = {};
    const trimmedFullName = formData.fullName.trim();
    const trimmedEmail = formData.email.trim();
    const trimmedDetails = formData.details.trim();
    if (!trimmedFullName) formErrors.fullName = 'Full name is required';
    if (!trimmedEmail) {
      formErrors.email = 'Email is required';
    } else if (!/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(formData.email)) {
      formErrors.email = 'Enter a valid email address';
    }
    if (!trimmedDetails) formErrors.details = 'Details are required';
    return formErrors;
  };


  const getCheckedItem = (checklist: IFormProps['checklist']) => {
    return Object.keys(checklist).find(key => checklist[key as keyof typeof checklist]);
  };


  const handleSubmit = async (e: any) => {
    e.preventDefault();
    const validationErrors = validate();
    if (Object.keys(validationErrors).length === 0) {
      try {
        setLoading(true);
        const checkedItem = getCheckedItem(formData.checklist);
        const dataToSend = {
          ...formData,
          checklist: checkedItem,
        };
        const response = await axios.post(
          ENDPOINTS.contacts,
          dataToSend,
          {
            headers: {
              'Content-Type': 'application/json',

            },
          }
        );
        setFormData({
          fullName: '',
          email: '',
          details: '',
          checklist: {
            WEB_DEV: true,
            MOBILE_DEV: false,
            SOFTWARE_MAINTAINER: false,
            OTHER: false,
          }
        });
        toast.success('We will get back to you soon')
        setLoading(false);
      } catch (error) {
        setLoading(false);
        toast.error('Some error occured.')
      }
    } else {
      setErrors(validationErrors);
      setTimeout(() => {
        setErrors({
          fullName: '',
          email: '',
          details: '',
        })
      }, 4000)
    }
  };





  return (
    <>
      <Toaster />
      <div className="container">

        {loading && (
          <div className="loader">
            <ClipLoader size={50} color={"#123abc"} loading={loading} />
          </div>
        )}


        <div className="header">
          <div className="logo">
            <img src={APP_LOGO} alt="App logo" className="img" />
          </div>
        </div>
        <div className='breadcrum-container'>
          <div className='breadcrum'>
            <h2 className="link-title" >
              <a
                className="link-title"
                href={`/`}
                onClick={() => { }}
              >
                Home
              </a>
            </h2>
            <h2 className="link-title">
              /
            </h2>
            <h2 className="link-description">
              Contact us
            </h2>
          </div>
          <div className='icon-container'>
            {/* <a href='' >
                        <img src={FBICON} alt='facebook' className='social-icon' />
                        </a> */}
            <a href='https://www.instagram.com/covelper/' target='_blank' >
              <img src={INSTAICON} alt='instagram' className='social-icon' />

            </a>
            <a href='https://www.linkedin.com/company/102430825' target='_blank'>
              <img src={LINKEDINICON} alt='linkedin' className='social-icon' />
            </a>

          </div>
        </div>

        <form onSubmit={handleSubmit}>
          {/* <div className="logo">
            <img src={C} alt="App logo" className="img" />
          </div> */}

          <div className="form-group">
            <label htmlFor="fullName">Full Name</label>
            <input
              id="fullName"
              name="fullName"
              type="text"
              value={formData.fullName}
              onChange={handleChange}
            />
            {errors.fullName && <div className="error">{errors.fullName}</div>}
          </div>
          <div className="form-group">
            <label htmlFor="email">Email</label>
            <input
              id="email"
              name="email"
              type="email"
              value={formData.email}
              onChange={handleChange}
            />
            {errors.email && <div className="error">{errors.email}</div>}
          </div>

          <div className="form-group">
            <label htmlFor="details">Details</label>
            <textarea
              id="details"
              name="details"
              value={formData.details}
              onChange={handleChange}
            ></textarea>
            {errors.details && <div className="error">{errors.details}</div>}
          </div>

          <div className="form-group">
            <label>Need help with?</label>
            <div className="checklist">
              <label>
                Website Development
                <input
                  type="checkbox"
                  name="WEB_DEV"
                  checked={formData.checklist.WEB_DEV}
                  onChange={handleChange}
                />
              </label>
              <label>
                Mobile Application Development
                <input
                  type="checkbox"
                  name="MOBILE_DEV"
                  checked={formData.checklist.MOBILE_DEV}
                  onChange={handleChange}
                />
              </label>
              <label>
                Software Maintainance
                <input
                  type="checkbox"
                  name="SOFTWARE_MAINTAINER"
                  checked={formData.checklist.SOFTWARE_MAINTAINER}
                  onChange={handleChange}
                />
              </label>
              <label>
                Other
                <input
                  type="checkbox"
                  name="OTHER"
                  checked={formData.checklist.OTHER}
                  onChange={handleChange}
                />
              </label>
            </div>
          </div>

          <button type="submit">Submit</button>
        </form>


      </div>

    </>
  )
}

export default Contact
