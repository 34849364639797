import FBICON from '../../icons/FB.png'
import INSTAICON from '../../icons/INSTAGRAM.png'
import LINKEDINICON from '../../icons/LINKEDIN.png'
import APP_LOGO from '../../icons/web_logo.png'
import Link from '../Link/Link'
import './Footer.css'




const Footer = () => {


    const handleClick = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>, section: string) => {
        event.preventDefault(); // Prevent the default behavior of the link
        const element = document.getElementById(section);
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' }); // Smoothly scroll to the element
        }
    };

    const handleCopy = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        event.preventDefault(); // Prevent the default behavior of the link
        const textToCopy = event.currentTarget.textContent; // Get the text content of the <a> tag
        if (textToCopy) {
            navigator.clipboard.writeText(textToCopy).then(() => {
                alert('Text copied to clipboard');
            }).catch(err => {
                console.error('Failed to copy text: ', err);
            });
        }
    };


    return (
        <div className='footer-container'>
            <section className='section-one'>
                <img src={APP_LOGO} alt='App logo' className='app-icon' />
                <p className='description'>
                    In the age of digital empowerment, we craft seamless connections,
                    With innovative technology and exceptional service as our directions.
                    We intertwine lives, pioneering groundbreaking solutions,
                    Creating a realm where connectivity fosters evolution.
                </p>
            </section>
            <section className='section-two'>
                <h2 className='title'>Service</h2>
                <p className='content'>Web Development</p>
                <p className='content'>Mob Application Development</p>
                <p className='content'>UI/UX Design</p>
                <p className='content'>QA Testing</p>
            </section>
            <section className='section-three'>
                <h2 className='title'>Sections</h2>
                <p >
                    <a
                        className='content'
                        href={`#home-section`}
                        onClick={(e) => handleClick(e, `home-section`)}
                    >
                        Home
                    </a>
                </p>
                <p >
                    <a
                        className='content'
                        href={`#service-section`}
                        onClick={(e) => handleClick(e, `service-section`)}
                    >
                        Service
                    </a>
                </p>
                <p >
                    <a
                        className='content'
                        href={`#about-section`}
                        onClick={(e) => handleClick(e, `about-section`)}
                    >
                        About us
                    </a>
                </p>

            </section>
            <section className='section-four'>
                <h2 className='title'>Contact</h2>
                <p>
                    <a
                        className="content"
                        href="#"
                        onClick={handleCopy}
                    >
                        Support@covelper.com
                    </a>
                </p>
                <p>
                    <a
                        className="content"
                        href="#"
                        onClick={handleCopy}
                    >
                        +92 324 4421920
                    </a>
                </p>
                <p>
                    <a
                        className="content"
                        href="#"
                        onClick={handleCopy}
                    >
                        +92 335 6120206
                    </a>
                </p>
                <h2 className='title'>Social Media</h2>
                <div className='icon-container'>
                    {/* <a href='' >
                        <img src={FBICON} alt='facebook' className='social-icon' />
                    </a> */}
                    <a href='https://www.instagram.com/covelper/' target='_blank' >
                        <img src={INSTAICON} alt='instagram' className='social-icon' />

                    </a>
                    <a href='https://www.linkedin.com/company/102430825' target='_blank'>
                        <img src={LINKEDINICON} alt='linkedin' className='social-icon' />
                    </a>

                </div>
            </section>
        </div>
    );
};

export default Footer;