import { useEffect, useState } from 'react';
import './Services.css'
import { useLocation } from 'react-router-dom';
import APP_LOGO from '../../icons/web_logo.png'
import INSTAICON from '../../icons/INSTAGRAM.png'
import LINKEDINICON from '../../icons/LINKEDIN.png'


const Services = () => {
  const location = useLocation();
  const { state } = location;
  let service: ICardDataProps = state?.data;

  return (
    <div className='services-container'>
      <div className="header">
        <div className="logo">
          <img src={APP_LOGO} alt="App logo" className="img" />
        </div>
      </div>
      <div className='breadcrum-container'>
        <div className='breadcrum'>
          <h2 className="link-title" >
            <a
              className="link-title"
              href={`/`}
              onClick={() => { }}
            >
              Home
            </a>
          </h2>
          <h2 className="link-title">
            /
          </h2>
          <h2 className="link-description">
            {service.title}
          </h2>
        </div>
        <div className='service-icon-container'>
          {/* <a href='' >
                        <img src={FBICON} alt='facebook' className='social-icon' />
                        </a> */}
          <a href='https://www.instagram.com/covelper/' target='_blank' >
            <img src={INSTAICON} alt='instagram' className='service-social-icon' />

          </a>
          <a href='https://www.linkedin.com/company/102430825' target='_blank'>
            <img src={LINKEDINICON} alt='linkedin' className='service-social-icon' />
          </a>

        </div>
      </div>

      <div className="service-detail">
        <h1 className="service-detail__header">{service.title}</h1>
        <p className="service-detail__description">{service.description}</p>
        <h2 className="service-detail__subheader">Technologies</h2>
        <ul className="service-detail__list">
          <li className="service-detail__list">
            {service?.technologies.map((step: any, index: number) => (
              <li key={index}>{step}</li>
            ))}
          </li>
        </ul>
        <h2 className="service-detail__subheader">Process</h2>
        <ol className="service-detail__list">
          {service?.process.map((step: any, index: number) => (
            <li key={index}>{step}</li>
          ))}
        </ol>
      </div>

      <div className="services-footer-bottom">
        <p>&copy; {new Date().getFullYear()} Covelper. All rights reserved.</p>
      </div>

    </div>
  )
}

export default Services
