import ServiceIcon from '../../icons/SERVICES.png'
import WebDev from '../../icons/WEBDEVELOPMENT.png';
import UIUX from '../../icons/UIUX.png'
import MobileApp from '../../icons/MOBILEAPPDEVELOPMENT.png'
import CustomDev from '../../icons/CUSTOMSOFTWARE.png'
import Qa from '../../icons/QA.png'
import SoftwareMaintainance from '../../icons/SOFTWAREMAINTAINANCE.png'
import './ServiceSection.css'
import { BackgroundTitle } from '../BackgroundTitle/BackgroundTitle';
import Card from '../Card/Card';
import { useNavigate } from 'react-router-dom';


const cardData: ICardDataProps[] = [
    {
        id: 0,
        image: WebDev,
        title: 'Web Development',
        description: 'Experience exceptional web development services with our customized solutions. From inception to deployment, we deliver outcomes that surpass expectations.',
        technologies: [
            "Frontend: React | JavaScript | TypeScript",
            "Backend: Node js | .net Core | Python",
            "Database: MongoDB | SQLite | Firebase | Realm | MySQL | PostgreSQL",
            "Authentication: Google Auth"
        ],
        process: [
            "Requirement Gathering: We start by understanding your business needs and goals.",
            "Design: Our UI/UX team creates mockups and wireframes for a user-centric design.",
            "Development: Using React, our developers create a scalable and maintainable frontend. Node.js and MongoDB are used for backend and database management.",
            "Testing: Rigorous QA testing ensures that the website is bug-free and performs well.",
            "Deployment and Maintenance: We deploy the website and provide ongoing maintenance and support."
        ]
    },
    {
        id: 1,
        image: UIUX,
        title: 'UI / UX Design',
        description: 'Elevate user experiences with our intuitive UI/UX design services, blending creativity with functionality to captivate your audience and drive engagement.',
        technologies: [
            "Design tools: Figma | Adobe XD | Sketch",
            "Collaboration tools: InVision | eplin"
        ],
        process: [
            "User Research: Understanding user needs and behaviors.",
            "Wireframing: Creating the skeletal framework of the UI.",
            "Prototyping: Developing interactive prototypes to validate design concepts.",
            "Design: Crafting high-fidelity UI designs with a focus on aesthetics and usability.",
            "Handoff: Collaborating with developers for smooth implementation."
        ]
    },
    {
        id: 2,
        image: Qa,
        title: 'QA Testing',
        description: 'Experience top-tier QA testing services with our tailored solutions. From test planning to execution, we ensure results that exceed expectations.',
        technologies: [
            "TestingTools: Selenium | Jest | Cypress",
            "CI_CD: Jenkins | Travis CI",
            "ReportingTools: JIRA | TestRail"
        ],
        process: [
            "Test Planning: Identifying test requirements and strategies.",
            "Test Design: Creating detailed test cases and scenarios.",
            "Test Execution: Running tests and logging results.",
            "Defect Tracking: Identifying and reporting defects.",
            "Test Reporting: Providing comprehensive test reports and insights."
        ]
    },
    {
        id: 3,
        image: MobileApp,
        title: 'Mobile Application Development',
        description: 'Elevate your digital presence with our customized app development solutions, tailored to meet your unique business needs and exceed user expectations.',
        technologies: [
            "Frontend: React Native | JavaScript | TypeScript",
            "Backend:  Node.js | .net Core | Python",
            "Database: MongoDB | SQLite | Firebase | Realm | MySQL | PostgreSQL",
            "OtherTools: Firebase | AWS"
        ],
        process: [
            "Requirement Analysis: Understanding mobile app objectives and requirements.",
            "UI/UX Design: Creating engaging and intuitive designs for mobile users.",
            "Development: Building cross-platform mobile applications using React Native.",
            "Testing: Conducting rigorous tests to ensure app quality and performance.",
            "Deployment: Publishing the app to app stores and providing maintenance."
        ]
    },
    {
        id: 4,
        image: CustomDev,
        title: 'Custom Sotfware Development',
        description: 'Experience excellence in custom software development with our bespoke solutions. From ideation to deployment, we deliver results that surpass expectations.',
        technologies: [
            "Languages: JavaScript | Python | C#",
            "Frameworks: React | NET | Django",
            "Database: PostgreSQL | MongoDB | SQLite | Firebase | Realm | MySQL",
            "DevOpsTools: Docker | Kubernetes"
        ],
        process: [
            "Ideation: Collaborating to define software requirements and scope.",
            "Design: Crafting the architecture and design of the software solution.",
            "Development: Coding the software using the chosen tech stack.",
            "Testing: Ensuring the software is reliable and bug-free.",
            "Deployment and Support: Implementing the software and providing ongoing support."
        ]
    },
    {
        id: 5,
        image: SoftwareMaintainance,
        title: 'Software Maintenance and Support',
        description: 'Experience exceptional software maintenance and support with our tailored solutions. From troubleshooting to updates, we ensure results that exceed expectations.',
        technologies: [
            "MonitoringTools: New Relic | Datadog",
            "IssueTracking: JIRA | Asana",
            "Communication: Slack | Microsoft Teams"
        ],
        process: [
            "Monitoring: Continuously tracking software performance and availability.",
            "Maintenance: Regularly updating and patching the software.",
            "Troubleshooting: Quickly identifying and resolving issues.",
            "User Support: Providing technical assistance to users.",
            "Improvement: Gathering feedback and implementing enhancements."
        ]
    },
];

const ServiceSection = () => {
    const navigate = useNavigate();

    const handleCardClick = (id: number) => {
        let data: ICardDataProps = cardData[id];
        navigate('/services', { state: { data } });
    };
    return (
        <section id="service-section" className="text-center">
            <div className="service-content">
                <div className='service-bg'>
                    <BackgroundTitle icon={ServiceIcon} paddingTop="1rem" />
                </div>
                <div className="service-subcontent">
                    <h1 className='title-text'>
                        Your Vision Our Expertise Elevate Your Brand with Our
                    </h1>
                    <h1 className='description-text'>
                        Suite of Services.
                    </h1>
                    <div className="card-grid-container">
                        {cardData.map((card) => (
                            <div key={card.id} >
                                <Card
                                    onClick={() => handleCardClick(card.id)}
                                    image={card.image}
                                    title={card.title}
                                    description={card.description}
                                />
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ServiceSection
