import React from 'react';
import './Link.css'

interface LinksProps {
    title: string;
    href: string;
    onEnd?: () => void;
}

const Link: React.FC<LinksProps> = ({ title, href, onEnd }) => {
    const handleClick = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        event.preventDefault(); // Prevent the default behavior of the link
        const element = document.getElementById(href);
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' }); // Smoothly scroll to the element
            onEnd && onEnd()
        }
    };

    return (
        <div className="link-container">
            <h2 className="link-title">
                <a

                    href={`#${href}`}
                    onClick={handleClick}
                >
                    {title}
                </a>
            </h2>
        </div>
    );
};

export default Link;
