import React from 'react'
import AboutUsIcon from '../../icons/ABOUTUS.png'
import Star from '../../icons/STAR.png'
import AboutUsMeeting from '../../icons/AboutUsMeeting.png'
import { BackgroundTitle } from '../BackgroundTitle/BackgroundTitle'
import './AboutSection.css'




const AboutUsSection = () => {
    return (
        <section id="about-section" className="text-center">
            <div className="about-content">
                <div className='about-bg'>
                    <BackgroundTitle paddingTop="6rem" icon={AboutUsIcon} />
                </div>
                <div className="about-subcontent-c">
                    <div className="about-subcontent">
                        <div className='text-container'>
                            <h1 className='title-text'>
                                About the
                            </h1>
                            <h1 className='title-text-c'>
                                COVELPER AGENCY
                            </h1>
                            <div className='star-icon-container'>
                                <img className='star-icon' src={Star} alt='meeting' />
                            </div>
                        </div>
                        <div className='decription-container'>
                            <h3 className='description-text'>
                                At COLVER, we're more than just a team of digital enthusiasts; we're your partners in crafting exceptional brand experiences. With a passion for creativity and a dedication to excellence, we collaborate closely with our clients to bring their visions to life. From innovative design to strategic marketing,
                                we're here to empower your brand and propel your success forward. Let's embark on this journey together.
                            </h3>
                        </div>
                    </div>
                    <div className="meeting-icon-container">
                        <img className='meeting-icon' src={AboutUsMeeting} alt='meeting' />
                    </div>
                </div>
            </div>
        </section>
    )
}

export default AboutUsSection
