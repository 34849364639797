import React from 'react';
import './BackgroundTitle.css'

interface BackgroundTitleProps {
    icon: any;
    paddingTop?: string;
}

export const BackgroundTitle: React.FC<BackgroundTitleProps> = ({ icon, paddingTop }) => {
    return (
        <div className="background-container" style={{ paddingTop }}>
            <img src={icon} alt='background' className='background-img' />
        </div>
    );
}
