import AboutUsSection from '../../components/AcountSection/AboutUsSection'
import ScrollButton from '../../components/BackonTop/BackonTop'
import Footer from '../../components/Footer/Footer'
import HomeSection from '../../components/HomeSection/HomeSection'
import Navigationbar from '../../components/NavBar/Navbar'
import ServiceSection from '../../components/ServiceSection/ServiceSection'
import './Home.css'


const Home = () => {
  return (
    <div className='main-container'>
      <Navigationbar />
      <HomeSection />
      <ServiceSection />
      <AboutUsSection />
      <Footer />
      <ScrollButton />
    </div>
  )
}

export default Home
