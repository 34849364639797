import styled from "styled-components";

export const Heading = styled.h1`
  text-align: center;
  color: green;

  @media (max-width: 768px) {
    font-size: 1.5rem; // Adjust font size for smaller screens
  }
`;

export const Content = styled.div`
  overflow-y: scroll; // Corrected typo
  height: 2500px;

  @media (max-width: 768px) {
    height: 1500px; // Adjust height for smaller screens
  }
`;

export const Button = styled.div`
  position: fixed;
  width: 100%;
  display: grid;
  align-items: center;
  justify-content: center;
  bottom: 40px;
  height: 20px;
  font-size: 3rem;
  z-index: 1;
  cursor: pointer;
  color: #9eff00;

  @media (max-width: 768px) {
    font-size: 2.5rem; // Adjust font size for smaller screens
    bottom: 25px; // Adjust position for smaller screens
  }

  @media (max-width: 480px) {
    font-size: 2rem; // Further adjust font size for very small screens
    bottom: 20px; // Further adjust position for very small screens
  }
`;
