import { useState } from "react";
import APP_LOGO from '../../icons/web_logo.png'

import Link from "../Link/Link";
import './Navigationbar.css';
import { Hamburger } from "../../svgs";
import { useNavigate } from 'react-router-dom';

const Navigationbar = () => {
    const navigate = useNavigate();
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    return (
        <div className="navbar">
            <div className="navbar-content">
                <div className="logo">
                    <img src={APP_LOGO} alt="App logo" className="img" />
                </div>
                <div className="menu-toggle" onClick={toggleMenu}>
                    <Hamburger />
                </div>
                <ul className="menu-items">
                    <li>
                        <Link title="Home" href="home-section" />
                    </li>
                    <li>
                        <Link title="Service" href="service-section" />
                    </li>
                    <li>
                        <Link title="About us" href="about-section" />
                    </li>
                </ul>
                <button onClick={() => navigate('/contact-us')} className="contact-button">
                    Contact us
                </button>
            </div>
            <div className={`menu ${isMenuOpen ? 'show' : ''}`}>
                <ul className="menu-items" style={{ paddingLeft: 0 }} >
                    <li>
                        <Link title="Home" href="home-section" onEnd={() => { setIsMenuOpen(false) }} />
                    </li>
                    <li>
                        <Link title="Service" href="service-section" onEnd={() => { setIsMenuOpen(false) }} />
                    </li>
                    <li>
                        <Link title="About us" href="about-section" onEnd={() => { setIsMenuOpen(false) }} />
                    </li>
                    <li>
                        <button onClick={() => navigate('/contact-us')} className="contact-button">
                            Contact us
                        </button>
                    </li>
                </ul>
            </div>
        </div>
    );
};






export default Navigationbar;






